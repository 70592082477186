import dupeClient from "./DupeClient";
import dupeBusinessClient from "./DupeBusinessClient";

export async function getTrendingAesthetics() {
    return await dupeClient.get("/api/v1/aesthetics/content/trending").catch(error => {
        console.log(error);
    });
}

export async function getPopularAesthetics() {
    return await dupeBusinessClient.get("/api/v1/aesthetics/creators/popular").catch(error => {
        console.log(error);
    });
}