import dupeBusinessClient from "./DupeBusinessClient";

export async function getSocialAccounts() {
    return await dupeBusinessClient.get("/api/v1/businesses/social/accounts").catch(error => {
        console.log(error);
    });
}

export async function getBrandHub() {
    return await dupeBusinessClient.get("/api/v1/businesses/brand_hub").catch(error => {
        console.log(error);
    });
}

export async function updateBusinessInfo(req) {
    return await dupeBusinessClient.put("/api/v1/businesses", req).catch(error => {
        console.log(error);
    });
}

export async function getBusinessUsers() {
    return await dupeBusinessClient.get("/api/v1/businesses/users").catch(error => {
        console.log(error);
    });
}

export async function inviteUser(req) {
    return await dupeBusinessClient.post("/api/v1/businesses/users/invite", req);
}

export async function getSubscription() {
    return await dupeBusinessClient.get("/api/v1/businesses/subscription").catch(error => {
        console.log(error);
    });
}

export async function getStripeIntent() {
    return await dupeBusinessClient.get("/api/v1/payment_methods/intent").catch(error => {
        console.log(error);
    });
}

export async function createPaymentMethod(req) {
    return await dupeBusinessClient.post("/api/v1/payment_methods", req).catch(error => {
        console.log(error);
    });
}

export async function getPaymentMethods() {
    return await dupeBusinessClient.get("/api/v1/payment_methods").catch(error => {
        console.log(error);
    });
}

export async function getBusinessContent(page, campaignId, userId) {
    let url = "/api/v1/businesses/content/all?page=";
    if (page != null) {
        url = url + page;
    } else {
        url = url + '1';
    }
    if (campaignId != null) {
        url = url + '&campaignId=' + campaignId;
    }
    if (userId != null) {
        url = url + '&userId=' + userId;
    }
    return await dupeBusinessClient.get(url).catch(error => {
        console.log(error);
    });
}

export async function getBusinessCreators() {
    return await dupeBusinessClient.get("/api/v1/businesses/creators").catch(error => {
        console.log(error);
    });
}

export async function getBusinessNotifications() {
    return await dupeBusinessClient.get("/api/v1/businesses/notifications").catch(error => {
        console.log(error);
    });
}

export async function getActiveBusinessCreators(campaignId) {
    let url = "/api/v1/businesses/creators/active"
    if (campaignId != null) {
        url = url + "?campaignId=" + campaignId
    }
    return await dupeBusinessClient.get(url).catch(error => {
        console.log(error);
    });
}

export async function getActiveBusinessCreatorCampaigns(campaignId) {
    return await dupeBusinessClient.get("/api/v1/businesses/creators/campaigns").catch(error => {
        console.log(error);
    });
}

export async function getBusinessSummary() {
    return await dupeBusinessClient.get("/api/v1/businesses/summary").catch(error => {
        console.log(error);
    });
}

export async function getBusinessInviteSummary(inviteId) {
    return await dupeBusinessClient.get("/api/v1/businesses/invite/" + inviteId + "/summary");
}

export async function acceptBusinessInviteSummary(inviteId) {
    return await dupeBusinessClient.get("/api/v1/businesses/invite/" + inviteId + "/accept").catch(error => {
        console.log(error);
    });
}

export async function createBusinessImage(request) {
    return await dupeBusinessClient.post("/api/v1/businesses/image", request, { headers: {'Content-Type': 'multipart/form-data'}}).catch(error => {
        console.log(error);
    });
}