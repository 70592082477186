import React, {useEffect, useRef, useState} from 'react'
import "../stylesheets/BrandHub.css";
import {getCampaigns} from "../services/CampaignService";
import Content from "./Content";
import {getBusinessContent, getBusinessCreators} from "../services/BusinessService";
import {Image, StyleSheet, Text, View} from 'react-native-web';
import blueLandscapeIcon from '../icons/blue-landscape-icon.png';
import Header from "./Header";
import {useSearchParams} from "react-router-dom";



const ContentHub = () => {

    const [content, setContent] = useState([])
    const [additionalContent, setAdditionalContent] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [creators, setCreators] = useState([])
    const selectedCampaignId = useRef();
    const selectedUserId = useRef();
    const [page, setPage] = useState(2)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        getBusinessContent(null, searchParams.get("campaign"), searchParams.get("user"))
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setContent(resp.data);
                    selectedCampaignId.current = searchParams.get("campaign");
                    selectedUserId.current = searchParams.get("user");
                }
            })
        getCampaigns()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCampaigns(resp.data);
                }
            })
        getBusinessCreators()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCreators(resp.data);
                }
            })
    }, [searchParams]);

    function getCampaignContent(campaignId) {
        getBusinessContent(null, campaignId, null)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setContent(resp.data);
                    selectedCampaignId.current = campaignId;
                    selectedUserId.current = null;
                    setPage(2);
                    searchParams.delete("campaign");
                    searchParams.delete("user");
                    searchParams.append("campaign", campaignId);
                    setSearchParams(searchParams);
                }
            })
    }

    function getUsersCampaignContent(userId) {
        getBusinessContent(null, null, userId)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setContent(resp.data);
                    selectedCampaignId.current = null;
                    selectedUserId.current = userId;
                    setPage(2);
                    searchParams.delete("campaign");
                    searchParams.delete("user");
                    searchParams.append("user", userId);
                    setSearchParams(searchParams);
                }
            })
    }

    function retrieveNextContent() {
        getBusinessContent(page, selectedCampaignId.current, selectedUserId.current)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setAdditionalContent(resp.data);
                    setPage(page+1);
                }
            })
    }

    return(
        <div>
            <Header title={"Content"}/>
            <div style={styles.contentDiv}>
                <Content retrieveNextContent={retrieveNextContent} content={content} additionalContent={additionalContent}/>
            </div>
            <div style={styles.settingsDiv}>
                <div style={styles.settingsHeaderDiv}>
                    <Text style={styles.h3}>Filters</Text>
                </div>
                <Text style={styles.h5}>Campaigns</Text>
                {campaigns && campaigns.length > 0 && campaigns.map(campaign => (
                    <View style={[styles.campaignPreviewDiv, (selectedCampaignId.current === campaign.id) ? styles.selectedFilterDiv : {}]} onClick={() => {getCampaignContent(campaign.id)}}>
                        <Image style={styles.campaignImg} source={campaign.imageExists 
                            ? {uri: 'https://d3p3fw3rutb1if.cloudfront.net/business/photos/' + campaign.id}
                            : blueLandscapeIcon
                        } />
                        <div style={styles.campaignInfoDiv}>
                            <p style={styles.inline}>{campaign.name}</p>
                        </div>   
                    </View>
                ))}
                <Text style={styles.h5}>Creators</Text>
                {creators && creators.length > 0 && creators.map(creator => (
                    <View style={[styles.campaignPreviewDiv, (selectedUserId.current === creator.id) ? styles.selectedFilterDiv : {}]} onClick={() => {getUsersCampaignContent(creator.id)}}>
                        <Image style={styles.campaignImg} source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/users/" + creator.id}} />
                        <div style={styles.creatorInfoDiv}>
                            <p style={styles.creatorInfoP}>{creator.first_name} {creator.last_name}</p>
                            <p style={styles.creatorInfoP}>{creator.posts} uploads</p>
                        </div>
                    </View>
                ))}
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    settingsDiv: {
        display: 'inline-block',
        width: '20%',
        height: 'calc(100vh - 50px)',
        verticalAlign: 'top',
        overflow: 'scroll',
    },
    contentDiv: {
        display: 'inline-block',
        width: '80%',
        height: 'calc(100vh - 50px)',
        overflow: 'scroll',
        verticalAlign: 'top',
    },
    settingsHeaderDiv: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    campaignImg: {
        width: '50px',
        height: '50px',
        display: 'inline-block',
        objectFit: 'cover',
        borderRadius: '5px',
    },
    campaignPreviewDiv: {
        cursor: 'pointer',
        paddingLeft: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start', 
        marginBottom: '10px',
        paddingTop: '5px', 
        paddingBottom: '5px',
        flexDirection: 'row',
    },
    selectedFilterDiv: {
        backgroundColor : '#b8cbf5',
    },
    campaignInfoDiv: {
        flex: '1',
        whiteSpace: 'normal',
        marginLeft: '10px',
        marginRight: '10px',
    },
    inline: {
        display: 'inline-block',
        wordWrap: 'break-word',
        lineHeight: '1.2em'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    creatorInfoP: {
        marginBottom: 0,
    },
    creatorInfoDiv: {
        display: 'inline-block',
        verticalAlign: 'top',
        marginLeft: '10px',
    },
    h3: {
        color: '#396de0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    h5: {
        color: '#396de0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
});

export default ContentHub
