import React, { useEffect, useRef, useState } from 'react'
import { searchCreators } from "../services/CreatorService";
import "../stylesheets/CreatorSearch.css";
import dLogo from "../icons/d-logo.png";
import loadingIcon from "../icons/loading-icon.png";
import downArrowIcon from "../icons/DupeIcons-03-Arrow.png";
import { createCampaignCreator, getCampaigns } from "../services/CampaignService";
import {getPopularAesthetics, getTrendingAesthetics} from "../services/AestheticService";
import ReactPlayer from 'react-player/lazy'
import Header from './Header';
import {StyleSheet, View, Image, Text, ScrollView, Linking} from 'react-native-web';
import Button from './Button';
import instagramLogo from "../icons/DupeIcons-Instagram.png";
import blueLandscapeIcon from '../icons/blue-landscape-icon.png';
import Modal from 'react-modal';
import CampaignPreview from './CampaignPreview';

const CreatorSearch = () => {
    const [creators, setCreators] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [settings, setSettings] = useState({});
    const [settingOptions, setSettingOptions] = useState({});
    const [settingsExpanded, setSettingsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [addingCreatorId, setAddingCreatorId] = useState("");
    const [page, setPage] = useState(1);
    const [addToCampaignModal, setAddToCampaignModal] = useState(false)
    const [addingCreator, setAddingCreator] = useState({})
    const [totalCreators, setTotalCreators] = useState(0)
    const campaignAddRef = useRef(null);

    const json = require('country-region-data/data.json');
    const countryNames = json.map(country => country.countryName);

    // const advancedSettingOptions = {
    //     "Cost for one static IG post": [],
    //     "Cost for one IG Reel": [],
    //     "Cost for one TikTok post": [],
    //     "Licensing preference": [],
    //     "Follower count": [],
    //     "Top age of followers": [],
    //     "Top gender of followers": [],
    //     "Top location of followers": [],
    //     "Equipment": []
    // };

    function showSocial(user) {
        setCreators(creators.map(creator => {
            if (creator.id === user.id) {
                return { ...creator, showSocial: true };
            }
            return creator;
        }));
    }

    function showContent(user) {
        setCreators(creators.map(creator => {
            if (creator.id === user.id) {
                return { ...creator, showSocial: false };
            }
            return creator;
        }));
    }

    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }

    function toggleSettingsExpansion() {
        setSettingsExpanded(!settingsExpanded);
    }

    function addCreatorToCampaign(creatorId, campaignId) {
        let req = {};
        req.creatorId = creatorId;
        req.campaignId = campaignId;
        req.stage = 'SHORTLISTED';
        createCampaignCreator(req)
            .then(() => {
                setAddingCreatorId("");
            });
    }

    function updateSetting(event, setting) {
        let tempSetting = settings;
        tempSetting[setting] = event.target.value;
        setSettings(tempSetting);
        //haven't set up country search yet
        //tempSetting.Country = null;
        tempSetting.page = 1;
        setIsLoading(true);
        searchCreators(tempSetting)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCreators(resp.data.creators);
                    setTotalCreators(resp.data.totalCreators);
                    setPage(1);
                }
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    function paginate(page) {
        setCreators([]);
        setPage(page);
        let tempSetting = settings;
        //tempSetting.Country = null;
        tempSetting.page = page;
        if (tempSetting.Aesthetic === "All") {
            tempSetting.Aesthetic = null;
        }
        setIsLoading(true);
        searchCreators(tempSetting)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCreators(resp.data.creators);
                    setTotalCreators(resp.data.totalCreators);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    function closeModal() {
        setAddToCampaignModal(false);
        setAddingCreator({});
    }

    function openAddToCampaignModal(creator) {
        setAddingCreator(creator);
        setAddToCampaignModal(true);
    }

    useEffect(() => {
        setSettingOptions({
            "Country": ["All", ...countryNames],
            "Aesthetic": ["travel", "outdoorsy", "city girl", "beachcore"],
            "Compensation": ["All", "Product/Trade", "Paid"],
            "Popularity": ["Popularity", "Posts"]
        });
        const searchReq = {
            "popularity": "LIKES"
        }
        setIsLoading(true);
        searchCreators(searchReq)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCreators(resp.data.creators);
                    setTotalCreators(resp.data.totalCreators);
                }
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
        getCampaigns()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCampaigns(resp.data);
                }
            })
        getPopularAesthetics()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    let aesthetics = resp.data.map(aesthetic => {
                        return aesthetic.aesthetic
                    })
                    setSettingOptions(prevState => {
                        return ({ ...prevState, Aesthetic: ["All"].concat(aesthetics) })
                    })
                    //settingOptions.Aesthetic = "All".concat(resp.data)
                }
            })
        setSettings({
            "Country": "All",
            "Aesthetic": "All",
            "Compensation": "All",
            "Popularity": "All Time"
        })
        const handleClickOutside = (event) => {
            if (campaignAddRef.current && !campaignAddRef.current.contains(event.target)) {
                setAddingCreatorId("");
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };

    }, []);
    return (
        <div className={"creator-search-div"}>
            {isLoading && (
                <img src={loadingIcon} alt="" className={"loading-icon"} />
            )}
            <Header title={"Creator Search"} />
            <div className={"settings-nav-bar " + (settingsExpanded ? "expanded-settings-div" : "")}>
                {!settingsExpanded && (
                    <div className={"settings-div"}>
                        {Object.keys(settings).map(setting => (
                            <div className={"setting-div"}>
                                <p className={"blue-text font-bold"}>{setting}</p>
                                <p>{settings[setting]}</p>
                            </div>
                        ))}
                    </div>
                )}
                {settingsExpanded && (
                    <div className={"settings-div"}>
                        {Object.keys(settings).map(setting => (
                            <div className={"setting-div"}>
                                <p className={"blue-text font-bold"}>{setting}</p>
                                <select defaultValue={settings[setting]} onChange={(event) => {
                                    updateSetting(event, setting)
                                }} className="form-control" id="exampleFormControlSelect1">
                                    {settingOptions[setting] && settingOptions[setting].map(option => (
                                        <option>{option}</option>
                                    ))}
                                </select>
                            </div>
                        ))}
                    </div>
                )}
                <img className={"settings-down-img"} src={downArrowIcon} onClick={toggleSettingsExpansion} alt={""} />
            </div>
            {creators && creators.map(creator => (
                <View style={styles.creatorPreviewContainer}>
                    <View style={{ justifyContent: "center", gap: 10 }}>
                        <Image source={dLogo} style={styles.contentToggle} onClick={() => showContent(creator)} alt={""} />
                        {creator.instagram && creator.instagram.length > 0 && (
                            <Image source={instagramLogo} style={styles.contentToggle} onClick={() => showSocial(creator)} alt={""} />
                        )}
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
                            <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
                                <Image style={styles.profileImage}
                                    source={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + creator.id} alt={""} />
                                <View style={{ marginLeft: 15, alignContent: "center" }}>
                                    <Text style={styles.h3} onPress={() => Linking.openURL('https://dupephotos.com/profile/' + creator.username)}>{creator.first_name} {creator.last_name}</Text>
                                    <View style={{ flexDirection: "row" }}>
                                        {creator.aesthetics && creator.aesthetics.map(aesthetic => (
                                            <Text style={styles.aestheticText}>{aesthetic}</Text>
                                        ))}
                                    </View>
                                    <View style={{ flexDirection: "row", marginTop: 5 }}>
                                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                                            <Image style={{ width: 15, height: 15 }} source={dLogo} alt={""} />
                                            <Text style={styles.followerText}> {kFormatter(creator.follower_count)} Followers</Text>
                                        </View>
                                        {creator.instagram_follower_count > 0 && (
                                            <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 10 }}>
                                                <Image style={{ width: 15, height: 15 }} source={instagramLogo} alt={""} />
                                                <Text style={styles.followerText}> {kFormatter(creator.instagram_follower_count)} Followers</Text>
                                            </View>
                                        )}
                                    </View>
                                </View>
                            </View>
                            <View style={{ height: "100%", alignItems: "flex-end" }}>
                                <Button text="+ Campaign" onPress={() => openAddToCampaignModal(creator)} />
                            </View>
                        </View>
                        {/* <View> */}
                        <ScrollView horizontal={true} scrollEnabled={true} style={{ flexGrow: 0}}>
                            {!creator.showSocial && creator.content && creator.content.map(image => (
                                <div className={"d-inline-block"}>
                                    {image.content_type === "VIDEO" && (
                                        <View style={styles.videoWrapper}>
                                            <ReactPlayer width={"100%"} height={"20vh"} style={{ borderRadius: "50px" }} url={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + image.img_preview_id} muted={true} playing={true} loop={true} />
                                        </View>
                                    )}
                                    {image.content_type !== "VIDEO" && (
                                        // <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                                        <img className={"content-preview-img"}
                                            src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} alt={""} />
                                    )}
                                </div>
                            ))}
                            {creator.showSocial && creator.instagram && creator.instagram.map(image => (
                                <div className={"d-inline-block"}>
                                    {image.media_url !== "" && image.media_type === "VIDEO" && (
                                        <View style={styles.videoWrapper}>
                                            <ReactPlayer width={"100%"} height={"20vh"} style={{ borderRadius: "50px" }} url={image.media_url} muted={true} playing={true} loop={true} />
                                        </View>
                                    )}
                                    {image.media_url !== "" && image.media_type !== "VIDEO" && (
                                        <img className={"content-preview-img"} src={image.media_url} alt={""} />
                                    )}
                                </div>
                            ))}
                        </ScrollView>
                    </View>
                </View>
            ))}

            <div className={"pagination-div"}>
                <button className={page === 1 ? "selected-page-btn" : "page-btn"} onClick={() => paginate(1)}>1</button>
                {totalCreators > 5 && (
                    <button className={page === 2 ? "selected-page-btn" : "page-btn"} onClick={() => paginate(2)}>2</button>
                )}
                {page > 3 && (
                    <button className={"page-btn"} onClick={() => paginate(page - 1)}>{page - 1}</button>
                )}
                {page > 2 && (
                    <button className={"selected-page-btn"}>{page}</button>
                )}
                {page > 1 && totalCreators > (page*5) && (
                    <button className={"page-btn"} onClick={() => paginate(page + 1)}>{page + 1}</button>
                )}
                {totalCreators > ((page+1)*5) && (
                    <button className={"page-btn"} onClick={() => paginate(page + 2)}>{page + 2}</button>
                )}
                {totalCreators > ((page+2)*5) && (
                    <button className={"page-btn"} onClick={() => paginate(page + 3)}>{page + 3}</button>
                )}
                {totalCreators > ((page+3)*5) && (
                    <button className={"page-btn"} onClick={() => paginate(page + 4)}>{page + 4}</button>
                )}
                {totalCreators > ((page+4)*5) && (
                    <button className={"page-btn"} onClick={() => paginate(Math.ceil(totalCreators/5))}>...{Math.ceil(totalCreators/5)}</button>
                )}
            </div>

            <Modal
                isOpen={addToCampaignModal}
                onRequestClose={closeModal}
                style={styles.modal}
            >
                <View style={{backgroundColor: 'white', borderRadius: 10, padding: 20, height: '80%'}}>
                    <Text style={[styles.sectionText, {marginBottom: 10, alignSelf: 'center'}]}>Adding {addingCreator.first_name} {addingCreator.last_name} to Campaigns</Text>
                    <ScrollView showsVerticalScrollIndicator={true} ref={campaignAddRef}>
                        {campaigns && campaigns.map(campaign => (
                            <CampaignPreview key={campaign.id} alternateBackground={true} campaign={campaign} creatorId={addingCreator.id}/>
                        ))}
                    </ScrollView>
                </View>
            </Modal>
        </div>

    );
}

const styles = StyleSheet.create({
    videoWrapper: {
        width: '100%',
        height: '20vh',
        borderRadius: 10,
        overflow: 'hidden',
        marginLeft: 2.5,
    },
    profileImage: {
        width: 80,
        height: 80,
        borderRadius: 40,
    },
    h3: {
        color: '#396de0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    followerText: {
        fontFamily: "lato",
        fontSize: 14,
        color: "#737373",
    },
    aestheticText: {
        fontSize: 12,
        color: '#396de0',
        borderWidth: 1,
        borderColor: '#396de0',
        borderRadius: 50,
        fontFamily: "'lato', sans-serif",
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 5,
    },
    creatorPreviewContainer: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#b8cbf5',
        marginBottom: 15,
        borderRadius: 10,
        padding: 20,
        marginLeft: '2.5%',
        marginRight: '2.5%',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'scroll',
    },
    contentToggle: {
        width: 30,
        height: 30,
        cursor: "pointer"
    },
    campaignPreviewImage: {
        height: 80,
        width: 80,
        borderRadius: 5,
        margin: 10,
    },
    sectionText: {
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 30,
        fontWeight: 'bold',
        color: '#396de0',
    },
    modal: {
        overlay: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(57, 109, 224, .5)'
        },
        content: {
            //margin: '20%',
            margin: 'auto',
            width: '50%',
            inset: 0,
            background: 'none',
            height: '100%',
            flex: 1,
            border: 'none',
            alignSelf: 'center',
            alignContent: 'center',
        }
    },
});



export default CreatorSearch
